import React, { useState } from "react";
import tw from "twin.macro";
import { PrimaryButton } from "components/misc/Buttons.js";
import Modal from 'react-modal';

const ContactForm = () => {

  Modal.setAppElement('#root');
  const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
      setIsOpen(true);
    }

    function closeModal() {
      setIsOpen(false);
    }

  const [status, setStatus] = useState("Submit");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("Sending...");
    const { name, email, tel, message } = e.target.elements;
    let details = {
      name: name.value,
      email: email.value,
      tel:tel.value,
      message: message.value
    }

    let response = await fetch("https://chrisanalytics.com/contact", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(details),
    });
    setStatus("Submit");
    let result = await response.json();

    alert(result.status);
    closeModal();
  };


  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(255, 255, 255, 0.75)',
      'zIndex':'40',
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };



  return (
    <>
      <PrimaryButton tw="rounded-full mt-1" type="button" onClick={openModal}>
        Contact
      </PrimaryButton>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
      <div tw="flex flex-col justify-end items-end m-0 z-50">
        <button type="button" onClick={closeModal} tw="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
              <span tw="sr-only">Close menu</span>
              <svg tw="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
        </button>
      </div>
          <div tw="m-4">
            <form onSubmit={handleSubmit} id="contactForm" tw="w-full max-w-lg">
            <div>
              <div tw="text-center text-base underline font-bold">Contact Form</div>
              <div tw="flex flex-wrap -mx-3 mb-6">
                <label htmlFor="name">Name:</label>
                <input type="text" tw="form-input mt-1 block w-full" id="name" placeholder="Jane Doe" required />
              </div>
              <div tw="flex flex-wrap -mx-3 mb-6">
                <label htmlFor="email">Email:</label>
                <input tw="form-input mt-1 block w-full" type="email" id="email" placeholder="jane@example.com" required />
              </div>
              <div tw="flex flex-wrap -mx-3 mb-6">
                <label htmlFor="tel">Phone (Optional):</label>
                <input tw="form-input mt-1 block w-full" type="tel" id="tel" placeholder="(555)-555-5555" />
              </div>
              <div tw="flex flex-wrap -mx-3 mb-6">
                <label htmlFor="message">Message:</label>
                <textarea tw="form-textarea mt-1 block w-full" rows="3" id="message" required />
              </div>
            </div>
            <div tw="flex flex-col justify-center items-center">
              <PrimaryButton tw="rounded-full mt-1" type="submit">
                {status}
              </PrimaryButton>
            </div>
            </form>
          </div>
        </Modal>
      </>
  );
};

export default ContactForm;
