import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithFeaturesAndTestimonial.js";
import Features from "components/features/ThreeColSimple.js";
import MainFeature from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureStats from "components/features/ThreeColCenteredStatsPrimaryBackground.js";
import About from "components/features/TwoColWithButton.js"
import Blog from "components/blogs/GridWithFeaturedPost.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStartedLight.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import GuitarImage from "components/custom/guitarPic.jpg"
//import ContactForm from "components/ContactModal.js"

import { Element } from 'react-scroll'
import ContactForm from "components/ContactForm.js"

const HighlightedText = tw.span`text-primary-500`

var description ="Born and raised in Palo Alto with over ten years of experience working with data ranging from DNA genome sequences to operational and financial performance metrics. My passion is helping you find data zen through the design of clean and effective data architecture."
var description2 = "This means finding easy-to-use solutions to connect your data platforms including your accounting, financial planning and HRIS software as well as other cloud services such as Salesforce and Google Analytics."
var description3 = "I especially love working with organizations who have a mission, be it startups with a drive to innovate, or non-profits serving the community. "
var description4 = "Outside of helping businesses with their data, I am an avid guitarist/songwriter and active participant in the local DIY indie music scene."
export default () => {
  return (

    <AnimationRevealPage>
      <Element id="Home">
        <Hero
          heading="Getting the most out of your operations data."
        />
      </Element>
      <Element id="Services">
        <Features
          heading={<>Services</>}
        />
      </Element>
      <Element id="About">
        <About
          subheading="About Me"
          heading='"My passion is helping you find data zen..."'
          description={description}
          description2={description2}
          description3={description3}
          description4={description4}
          imageSrc={GuitarImage}
        />
      </Element>
      <Footer />
    </AnimationRevealPage>
  );
}
