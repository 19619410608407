import { useState } from "react";
import { useAnimation, useCycle } from "framer-motion";

//Below logic is for toggling the navbar when toggleNavbar is called. It is used on mobile toggling of navbar.
export default function useAnimatedNavToggler() {
  const [showNavLinks, setShowNavLinks] = useState(false);
  const [x, cycleX] = useCycle("0%", "100%");
  const animation = useAnimation();

  const toggleNavbar = () => {
    if (showNavLinks == true){
      animation.start({ x: x, display: "none" });
    } else {
      animation.start({ x: x, display: "block" });
    }
      setShowNavLinks(!showNavLinks);
      cycleX();
  };

  return {showNavLinks,animation, toggleNavbar }
}
